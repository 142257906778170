$black: #000000;
$white: #ffffff;
$red: #ce1526;
$serif: "ff-meta-serif-web-pro";
$sans: "graphik";
$compressed: "trade-gothic-next-compressed";

@font-face {
  font-family: "graphik";
  src: url("./fonts/Graphik-Black-Web.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: $sans;
  src: url("./fonts/Graphik-Regular-Web.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}
html {
  font-size: 16px;
  background-color: #fff;
  @media screen and (max-width: 1024px) {
    font-size: 14px;
  }
  @media screen and (max-width: 767px) {
    font-size: 12px;
  }
}
body {
  margin: 0;
  font-family: "graphik", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  border: 15px solid #fff;
}
header {
  h1 {
    color: #333;
    max-width: 930px;
    margin: 0 auto;
  }
  p {
    width: 100%;
    margin: 30px auto;
    line-height: 1.5;
    max-width: 930px;
  }
}
h1 {
  font-family: $sans;
}
p {
  font-family: $serif;
}
.row p {
  font-family: $sans;
}
[class$="-control"] > div {
  flex-wrap: nowrap;
}
a {
  transition: 0.3s;
  font-weight: 600;
  color: rgb(206, 21, 38);
}
a:hover {
  color: $black;
}
