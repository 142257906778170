@font-face {
  font-family: "graphik";
  src: url("./fonts/Graphik-Black-Web.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: "graphik";
  src: url("./fonts/Graphik-Regular-Web.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal; }

html {
  font-size: 16px;
  background-color: #fff; }
  @media screen and (max-width: 1024px) {
    html {
      font-size: 14px; } }
  @media screen and (max-width: 767px) {
    html {
      font-size: 12px; } }

body {
  margin: 0;
  font-family: "graphik", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.App {
  border: 15px solid #fff; }

header h1 {
  color: #333;
  max-width: 930px;
  margin: 0 auto; }

header p {
  width: 100%;
  margin: 30px auto;
  line-height: 1.5;
  max-width: 930px; }

h1 {
  font-family: "graphik"; }

p {
  font-family: "ff-meta-serif-web-pro"; }

.row p {
  font-family: "graphik"; }

[class$="-control"] > div {
  flex-wrap: nowrap; }

a {
  transition: 0.3s;
  font-weight: 600;
  color: #ce1526; }

a:hover {
  color: #000000; }
