.row--head.init {
  height: 500px;
}
.row:nth-child(2n + 1) {
  background-color: rgba(#d8d8d8, 0.5);
}
.row:first-child {
  background-color: transparent;
}
.datapoint {
}
.datapoint,
.datavalue {
}
.datavalue {
  // border-left: 1px solid #ffffff;
  // border-bottom: 1px solid #ffffff;
}
.row--head {
  .datapoint,
  .datavalue {
    border: 0;
  }
}
